import React, { Children, useContext, useState, useEffect } from 'react'
import { useAuth } from './AuthContext'
import { database, storage } from '../firebase'

const CartContext = React.createContext()

export function useCart() {
    return useContext(CartContext)
}

export default function CartProvider({ children }) {
    const { currentUser } = useAuth()
    const [currentCart, setCurrentCart] = useState()

    function updateItemQuantity(itemId, qty) {
        return database.carts.doc(currentUser.uid).collection("items").doc(itemId).update({
            "quantity": parseInt(qty)
        })
    }

    function removeItemAndFile(itemId) {
        return database.carts.doc(currentUser.uid).collection("items").doc(itemId).delete()
            .then(removeItemFile(itemId))
    }

    function removeItemFile(itemId) {
        return storage.ref(`/files/${currentUser.uid}/${itemId}/${itemId}.stl`).delete()
    }

    useEffect(() => {
        if (!currentUser) return null
        const unsubscribe = database.carts.doc(currentUser.uid).collection("items")
            .onSnapshot(snapshot => {
                setCurrentCart(snapshot.docs.map(database.formatDoc))
            })

        return unsubscribe
    }, [])

    const value = {
        currentCart,
        updateItemQuantity,
        removeItemAndFile
    }
    return (

        <CartContext.Provider value={value}>
            {children}
        </CartContext.Provider>
    )
}
