import React, { useState, useEffect } from 'react'
import { database } from '../../firebase'
import { useAuth } from '../../context/AuthContext'
import { Link } from 'react-router-dom'
import { Typography, Button, Paper, Box, Container, Snackbar } from '@material-ui/core'
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import OrderItem from './OrderItem'

export default function Orders() {
    const { currentUser } = useAuth()
    const [orders, setOrders] = useState()

    useEffect(() => {
        return database.orders
            .where("userId", "==", currentUser.uid)
            .where('status', 'in', [
                "payment_approved",
                "payment_in_process",
                "payment_refunded",
                "files_approved",
                "printing",
                "printed",
                "shipped",
                "delivered"
            ])
            .orderBy("timestamp", "desc")
            .onSnapshot(snapshot => {
                setOrders(snapshot.docs.map(database.formatDoc))
            })
    }, [])

    return (
        <>
            <Container>

                {!orders &&
                    <CircularProgress
                        sx={{
                            color: "#6622EE",
                            position: 'absolute',
                            top: '50%',
                            left: '50%'
                        }}
                    />
                }

                {orders && orders.map(order => (
                    <Box key={order.id} py={1}>
                        <Paper>
                            <Box py={2} px={2}>
                                <OrderItem order={order} />
                                <Link to={`/orders/${order.id}`}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                    >
                                        Ver Detalle
                                    </Button>
                                </Link>
                            </Box>

                        </Paper>
                    </Box>
                ))
                }
            </Container >
        </>
    )
}
