import React, { useRef, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useHistory } from 'react-router-dom';
import { TextField, Typography, Button } from '@material-ui/core'
import { Stack, Snackbar, Alert } from '@mui/material'
import CenteredContainer from "./CenteredContainer";
import { database } from '../../firebase';

export default function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { login } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [checked, setChecked] = useState(true)
    const history = useHistory()
    const paperStyle = {
        padding: "4vh",
        width: "100%",
        borderRadius: "15px",
        margin: "20px auto"
    }

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            setError('')
            setLoading(true)
            const cred = await login(email, password)
            
            await database.users.doc(cred.user.uid).update({
                lastLoggedIn: new Date()
            })

            history.push("/")
        } catch (error) {
            setError(error.message)
        }
        setLoading(false)
    }

    return (
        <CenteredContainer>

            <Snackbar
                open={error}
                autoHideDuration={3000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>

            <form onSubmit={handleSubmit} >

                <Stack spacing={4} direction="column">

                    <Stack spacing={2} direction="column">
                        <TextField
                            fullWidth
                            required
                            label='Email'
                            placeholder='Ingresar email'
                            type="email"
                            id="email"
                            value={email}
                            onInput={e => setEmail(e.target.value)}
                        />

                        <TextField
                            fullWidth
                            required
                            label='Contraseña'
                            placeholder='Ingresar contraseña'
                            type='password'
                            id="password"
                            value={password}
                            onInput={e => setPassword(e.target.value)}
                        />
                    </Stack>

                    <Button disabled={loading} type='submit' color='primary' variant="contained">
                    { loading ? "Ingresando..." : "Ingresar" }
                    </Button>

                    <Stack spacing={2} direction="column">
                        <Typography align='center'>
                            <Link style={{ color: '#6622EE' }} to="/forgot-password" >
                                ¿Olvidaste tu contraseña?
                            </Link>
                        </Typography>

                        <Typography align='center'>
                            ¿Sos nuevo? <Link style={{ color: '#6622EE' }} to="/signup">Creá una cuenta aquí</Link>
                        </Typography>
                    </Stack>

                </Stack>
            </form>

        </CenteredContainer >
    )
}
