import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import AuthProvider from './context/AuthContext';
import CartProvider from './context/CartContext';
import Profile from './components/authentication/Profile';
import PrivateRoute from './components/authentication/PrivateRoute';
import AuthRoute from './components/authentication/AuthRoute';
import AdminRoute from './components/authentication/AdminRoute';
import PublicRoute from './components/authentication/PublicRoute';
import Signup from './components/authentication/Signup';
import Login from './components/authentication/Login';
import ForgotPassword from './components/authentication/ForgotPassword';
import UpdateProfile from './components/authentication/UpdateProfile';
import Orders from "./components/orders/Orders";
import OrderDetail from "./components/orders/OrderDetail";
import AdminOrders from "./components/orders/AdminOrders";
import AdminOrderDetail from "./components/orders/AdminOrderDetail";
import AdminPayments from "./components/orders/AdminPayments";
import Home from './views/home/home'
import Cotizador from './views/cotizador/cotizador'
import Navbar from './components/navBar/navbar'

export default function App() {
  
  return (
    <Router>
      <AuthProvider>
        <CartProvider>
          <Navbar />
          <Switch>
            <PublicRoute exact path="/" component={Cotizador} />

            <PrivateRoute exact path="/orders" component={Orders} />
            <PrivateRoute path="/orders/:orderId" component={OrderDetail} />

            {/* Profile */}

            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/update-profile" component={UpdateProfile} />

            {/* Auth */}
            <AuthRoute path="/signup" component={Signup} />
            <AuthRoute path="/login" component={Login} />
            <AuthRoute path="/forgot-password" component={ForgotPassword} />

            {/* Admin */}
            <AdminRoute exact path="/admin" component={AdminOrders} />
            <AdminRoute path="/admin/payments" component={AdminPayments} />
            <AdminRoute path="/admin/:orderId" component={AdminOrderDetail} />

          </Switch>
        </CartProvider>
      </AuthProvider>
    </Router>
  )
}
