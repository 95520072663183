import React from 'react'
import { Box, Grid, Button, Container, Snackbar } from '@material-ui/core'
import { Link, NavLink } from "react-router-dom";
import { useAuth } from '../../context/AuthContext'
import NavbarMenu from './navbarMenu'
import { Stack, Alert } from '@mui/material'

function Navbar() {
    const { currentUser } = useAuth()

    return (
        <Box style={{
            height: 170,
            backgroundColor: "#2A2C36"
        }} >

            <Container maxWidth="lg" style={{ height: '100%' }} >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ height: '100%' }}
                >

                    <Snackbar
                        open={currentUser ? !currentUser.emailVerified : false}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}>
                        <Alert severity="error" sx={{ width: '100%' }}>
                            Debes verificar tu email para continuar
                        </Alert>
                    </Snackbar>

                    <Grid item>
                        <Box >
                            <NavLink to='/'>
                                <img src='images/logo.png' alt="" />
                            </NavLink>
                        </Box>
                    </Grid>

                    <Grid item >

                        <Stack direction="row" spacing={2}>

                            <NavLink to='/'>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    id="basic-button"
                                    style={{ outline: "none", backgroundColor: "fff" }}
                                >
                                    <strong>Cotizador</strong>
                                </Button>

                            </NavLink>

                            {currentUser ?
                                <NavbarMenu displayName={currentUser ? currentUser.displayName : ''} /> :
                                <NavLink to='/login'>
                                    <Button variant="contained" color="primary" className="btn-login"  >
                                        Iniciar sesión
                                    </Button>
                                </NavLink>
                            }

                        </Stack>

                    </Grid>

                </Grid>
            </Container>
        </Box >
    )
}

export default Navbar