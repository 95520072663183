import React from 'react'
import { useState, useEffect } from 'react'
import { TextField, MenuItem } from '@material-ui/core'
import { Stack, Snackbar, Alert } from '@mui/material'
import Typography from '@mui/material/Typography';

export default function ShippingFieldsReadOnly(props) {

    return (
        <Stack spacing={2} direction="column">

            <Typography variant="h5">
                Datos de Envío
            </Typography>

            <Typography variant="h6">
                Costo: ${props.shipping.cost}
            </Typography>


            <TextField
                disabled
                fullWidth
                required
                id="recipientName"
                label="Nombre"
                variant="outlined"
                size="small"
                value={props.shipping.name}
            />

            <Stack spacing={2} direction="row">
                <TextField
                    disabled
                    fullWidth
                    required
                    id="streetName"
                    label="Calle"
                    variant="outlined"
                    size="small"
                    value={props.shipping.streetName}
                />

                <TextField
                    disabled
                    fullWidth
                    required
                    id="streetNumber"
                    label="Altura"
                    variant="outlined"
                    size="small"
                    value={props.shipping.streetNumber}
                />
            </Stack>

            <Stack spacing={2} direction="row">
                <TextField
                    disabled
                    fullWidth
                    id="floor"
                    label="Piso"
                    variant="outlined"
                    size="small"
                    value={props.shipping.floor}
                />

                <TextField
                    disabled
                    fullWidth
                    id="unit"
                    label="Unidad"
                    variant="outlined"
                    size="small"
                    value={props.shipping.apartment}
                />
            </Stack>

            <Stack spacing={2} direction="row">
                <TextField
                    disabled
                    fullWidth
                    required
                    id="district"
                    label="Barrio"
                    variant="outlined"
                    size="small"
                    value={props.shipping.district}
                />

                <TextField
                    disabled
                    fullWidth
                    required
                    id="zipcode"
                    label="Codigo Postal"
                    variant="outlined"
                    size="small"
                    value={props.shipping.zipcode}
                />
            </Stack>

            <TextField
                disabled
                fullWidth
                multiline
                id="extraInfo"
                label="Información Adicional"
                variant="outlined"
                size="small"
                value={props.shipping.extraInfo}
            />

            <TextField
                disabled
                fullWidth
                id="city"
                placeholder="Localidad"
                value={props.shipping.cityName}
                variant="outlined"
                size="small"
            />

        </Stack>
    )
}
