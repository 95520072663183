import firebase from 'firebase/app'
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/functions"
import "firebase/analytics"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = firebase.initializeApp(firebaseConfig)

if (window.location.hostname === "localhost") {
    // firebase.firestore().useEmulator("localhost", 8080);
    firebase.functions().useEmulator("localhost", 5001);
    // firebase.storage().useEmulator("localhost", 9199);
}

const firestore = app.firestore()

export const database = {
    folders: firestore.collection('folders'),
    files: firestore.collection('files'),
    orders: firestore.collection('orders'),
    carts: firestore.collection('carts'),
    users: firestore.collection('users'),
    formatDoc: doc => {
        return { id: doc.id, ...doc.data() }
    },
    getCurrentTimestamp: a => {
        return ''
    }
}

export const storage = app.storage()
export const auth = app.auth()
export const functions = app.functions()
export const analytics = app.analytics();

export default app