import * as THREE from 'three'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { analytics } from '../../firebase'

let camera, cameraTarget, scene, renderer, controls
// export default function Stl(
//   width,
//   height,
//   url,
//   objectColor,
//   gridLineColor,
//   skyboxColor,
//   groundColor,
//   lightColor,
//   modelDimension
// ) {
//   // scene setup

//   scene = new THREE.Scene()
//   scene.background = new THREE.Color(skyboxColor)
//   scene.fog = new THREE.Fog(0xa0a0a0, 200, 1000)

//   // camera setup
//   camera = new THREE.PerspectiveCamera(45, width / height, 1, 1000)
//   camera.position.set(200, 100, 200)

//   cameraTarget = new THREE.Vector3(0, 0, 0)

//   // renderer setup
//   renderer = new THREE.WebGLRenderer({
//     antialias: true,
//     alpha: true,
//     preserveDrawingBuffer: true
//   })
//   renderer.setSize(width, height)

//   // where to render your scene
//   document.getElementById('stlviewer').innerHTML = ''
//   document.getElementById('stlviewer').appendChild(renderer.domElement)

//   // controls
//   controls = new OrbitControls(camera, renderer.domElement)
//   controls.target.set(0, 0, 0)
//   controls.enablePan = false
//   controls.enableDamping = true
//   controls.update()

//   // ground

//   // const ground = new THREE.Mesh(
//   //   new THREE.PlaneGeometry(2000, 2000),
//   //   new THREE.MeshPhongMaterial({ color: groundColor, depthWrite: false })
//   // )
//   // ground.rotation.x = -Math.PI / 2
//   // ground.receiveShadow = true
//   // scene.add(ground)

//   // const grid = new THREE.GridHelper(2000, 20, gridLineColor, gridLineColor)
//   // grid.material.opacity = 0.2
//   // grid.material.transparent = true
//   // scene.add(grid)

//   // lights
//   const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444)
//   hemiLight.position.set(0, 200, 0)
//   scene.add(hemiLight)

//   const directionalLight = new THREE.DirectionalLight(lightColor)
//   directionalLight.position.set(0, 200, 100)
//   // directionalLight.castShadow = true
//   // directionalLight.shadow.camera.top = 180
//   // directionalLight.shadow.camera.bottom = -100
//   // directionalLight.shadow.camera.left = -120
//   // directionalLight.shadow.camera.right = 120
//   scene.add(directionalLight)

//   // adding stl to scene
//   const loader = new STLLoader()
//   loader.load(url, (geometry) => {
//     const material = new THREE.MeshPhongMaterial({
//       color: objectColor,
//       specular: 0x111111,
//       shininess: 200
//     })
//     const mesh = new THREE.Mesh(geometry, material)

//     mesh.position.set(0, 0, 0)
//     mesh.rotation.set(-Math.PI / 2, 0, 0)
//     mesh.scale.set(1, 1, 1)

//     mesh.castShadow = true
//     mesh.receiveShadow = true

    // const signedVolumeOfTriangle = (p1, p2, p3) => {
    //   return p1.dot(p2.cross(p3)) / 6.0
    // }
    // let position = geometry.attributes.position
    // let faces = position.count / 3
    // let sum = 0
    // let p1 = new THREE.Vector3(),
    //   p2 = new THREE.Vector3(),
    //   p3 = new THREE.Vector3()
    // for (let i = 0; i < faces; i++) {
    //   p1.fromBufferAttribute(position, i * 3 + 0)
    //   p2.fromBufferAttribute(position, i * 3 + 1)
    //   p3.fromBufferAttribute(position, i * 3 + 2)
    //   sum += signedVolumeOfTriangle(p1, p2, p3)
    // }

//     scene.add(mesh)

//     // const geometry2 = new THREE.BoxGeometry( 1, 1, 1 );
//     // const material2 = new THREE.MeshBasicMaterial( {color: 0x00ff00} );
//     // const cube = new THREE.Mesh( geometry2, material2 );
//     // scene.add( cube );

    // // Bounding box
    // var box3 = new THREE.Box3();
    // var size = new THREE.Vector3();

    // box3.setFromObject( mesh ); // or from mesh, same answer
    // console.log( box3 );

    // box3.getSize( size ); // pass in size so a new Vector3 is not allocated
    
    // modelDimension({
    //   volume: sum,
    //   x: size.x.toFixed(2),
    //   y: size.y.toFixed(2),
    //   z: size.z.toFixed(2)
    // })

    // const fov = camera.fov * ( Math.PI / 180 );
    // const fovh = 2*Math.atan(Math.tan(fov/2) * camera.aspect);
    // let dx = size.z / 2 + Math.abs( size.x / 2 / Math.tan( fovh / 2 ) );
    // let dy = size.z / 2 + Math.abs( size.y / 2 / Math.tan( fov / 2 ) );
    // let cameraZ = Math.max(dx, dy);

    // // offset the camera, if desired (to avoid filling the whole canvas)
    // // if( offset !== undefined && offset !== 0 ) cameraZ *= offset;

    // camera.position.set( 0, size.y/2, cameraZ );

    // // set the far plane of the camera so that it easily encompasses the whole object
    // const minZ = box3.min.z;
    // const cameraToFarEdge = ( minZ < 0 ) ? -minZ + cameraZ : cameraZ - minZ;

    // camera.far = cameraToFarEdge * 3;
    // camera.updateProjectionMatrix();

    // const vector = new THREE.Vector3();
    // box3.getCenter(vector);
    // mesh.position.set(-vector.x, 0, -vector.z);

//     var helper = new THREE.BoxHelper(mesh, 0x000000);
//     helper.update();
//     // scene.add(helper);

    // if ( controls !== undefined ) {
    //     // set camera to rotate around the center
    //     controls.target = new THREE.Vector3(0, size.y/2, 0);
    //     cameraTarget = new THREE.Vector3(0, size.y/2, 0);

    //     // prevent camera from zooming out far enough to create far plane cutoff
    //     controls.maxDistance = cameraToFarEdge * 2;
    // }
//   })

//   // renderer

//   renderer.setPixelRatio(window.devicePixelRatio)
//   renderer.outputEncoding = THREE.sRGBEncoding

//   renderer.shadowMap.enabled = true

//   function onWindowResize() {
//     camera.aspect = window.innerWidth / height
//     camera.updateProjectionMatrix()

//     renderer.setSize(window.innerWidth, height)
//   }

//   const animate = () => {
//     requestAnimationFrame(animate)
//     render()
//   }

//   const render = () => {
//     camera.lookAt(cameraTarget)
//     renderer.render(scene, camera)
//   }
//   animate()
//   // window.addEventListener('resize', onWindowResize)
// }

export default function newSTL(
    width2,
    height2,
    url,
    objectColor,
    gridLineColor,
    skyboxColor,
    groundColor,
    lightColor,
    modelDimension
  )
   {
  // import Stats from './jsm/libs/stats.module.js';

  // import { OrbitControls } from './jsm/controls/OrbitControls.js';
  // import { RoomEnvironment } from './jsm/environments/RoomEnvironment.js';

  // import { GLTFLoader } from './jsm/loaders/GLTFLoader.js';
  // import { DRACOLoader } from './jsm/loaders/DRACOLoader.js';

  let mixer;

  const clock = new THREE.Clock();

  const canvas = document.querySelector('#stlviewer');
  const renderer = new THREE.WebGLRenderer({canvas});

  // const width = canvas.width
  // const height = canvas.height

  // const renderer = new THREE.WebGLRenderer( { antialias: true } );
  // renderer.setPixelRatio( window.devicePixelRatio );
  // renderer.setSize(width, height)

  // container.appendChild( renderer.domElement );









  renderer.outputEncoding = THREE.sRGBEncoding;
  

  const pmremGenerator = new THREE.PMREMGenerator( renderer );

  const scene = new THREE.Scene();
  scene.background = new THREE.Color(skyboxColor)
  // scene.background = new THREE.Color( 0xbfe3dd );
  // scene.environment = pmremGenerator.fromScene( new RoomEnvironment(), 0.04 ).texture;

  const pers = 1
  // const pers = width / height
  const camera = new THREE.PerspectiveCamera(45, pers, 1, 1000)
  camera.position.set(200, 100, 200)
  // const camera = new THREE.PerspectiveCamera( 40, window.innerWidth / window.innerHeight, 1, 100 );
  // camera.position.set( 5, 2, 8 );

  const controls = new OrbitControls( camera, renderer.domElement );
  controls.target.set(0, 0, 0);
  controls.update();
  controls.enablePan = false;
  controls.enableDamping = true;  

  const loader = new STLLoader()

  analytics.logEvent('stlLoaderStarted');

  loader.load(url, (geometry) => {

    analytics.logEvent('stlLoaderSuccess');

    const material = new THREE.MeshPhongMaterial({
      color: objectColor,
      specular: 0x111111,
      shininess: 200
    })
    const mesh = new THREE.Mesh(geometry, material)
    mesh.position.set(0, 0, 0)
    mesh.rotation.set(-Math.PI / 2, 0, 0)
    mesh.scale.set(1, 1, 1)

    scene.add(mesh)
    addLights()
    animate();


    // Testing

    const signedVolumeOfTriangle = (p1, p2, p3) => {
      return p1.dot(p2.cross(p3)) / 6.0
    }
    let position = geometry.attributes.position
    let faces = position.count / 3
    let sum = 0
    let p1 = new THREE.Vector3(),
      p2 = new THREE.Vector3(),
      p3 = new THREE.Vector3()
    for (let i = 0; i < faces; i++) {
      p1.fromBufferAttribute(position, i * 3 + 0)
      p2.fromBufferAttribute(position, i * 3 + 1)
      p3.fromBufferAttribute(position, i * 3 + 2)
      sum += signedVolumeOfTriangle(p1, p2, p3)
    }

        // Bounding box
        var box3 = new THREE.Box3();
        var size = new THREE.Vector3();
    
        box3.setFromObject( mesh ); // or from mesh, same answer
        console.log( box3 );
    
        box3.getSize( size ); // pass in size so a new Vector3 is not allocated
        
        modelDimension({
          volume: sum,
          x: size.x.toFixed(2),
          y: size.z.toFixed(2), // Y and Z are switched by design to match 3D printer coordinate system
          z: size.y.toFixed(2)
        })







        const fov = camera.fov * ( Math.PI / 180 );
        const fovh = 2*Math.atan(Math.tan(fov/2) * camera.aspect);
        let dx = size.z / 2 + Math.abs( size.x / 2 / Math.tan( fovh / 2 ) );
        let dy = size.z / 2 + Math.abs( size.y / 2 / Math.tan( fov / 2 ) );
        let cameraZ = Math.max(dx, dy);
    
        // offset the camera, if desired (to avoid filling the whole canvas)
        // if( offset !== undefined && offset !== 0 ) cameraZ *= offset;
    
        camera.position.set( 0, size.y/2, cameraZ );
    
        // set the far plane of the camera so that it easily encompasses the whole object
        const minZ = box3.min.z;
        const cameraToFarEdge = ( minZ < 0 ) ? -minZ + cameraZ : cameraZ - minZ;
    
        camera.far = cameraToFarEdge * 3;
        camera.updateProjectionMatrix();
    
        const vector = new THREE.Vector3();
        box3.getCenter(vector);
        mesh.position.set(-vector.x, 0, -vector.z);

        // var helper = new THREE.BoxHelper(mesh, 0x000000);
        // helper.update();
        // scene.add(helper);

      if ( controls !== undefined ) {
        // set camera to rotate around the center
        controls.target = new THREE.Vector3(0, size.y/2, 0);
        cameraTarget = new THREE.Vector3(0, size.y/2, 0);
        camera.lookAt(cameraTarget)

        // prevent camera from zooming out far enough to create far plane cutoff
        controls.maxDistance = cameraToFarEdge * 2;
      }

      

    // Testing







  }, (progress) => {
    console.log("loader progress: ", (progress.loaded / progress.total) * 100)
  },
  (error) => {
    console.log("loader error: ", error)
    analytics.logEvent(`stlLoaderError`, { error: error });
  })

  function resizeRendererToDisplaySize(renderer) {
    const canvas = renderer.domElement;
    const pixelRatio = window.devicePixelRatio;
    const width  = canvas.clientWidth  * pixelRatio | 0;
    const height = canvas.clientHeight * pixelRatio | 0;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  }

  function addLights() {
    // lights
    // const ambientLight = new THREE.AmbientLight(0xffffff, 1)
    // scene.add(ambientLight)

    const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444)
    hemiLight.position.set(0, 200, 0)
    scene.add(hemiLight)

    const directionalLight = new THREE.DirectionalLight(lightColor)
    directionalLight.position.set(0, 200, 100)
    // directionalLight.castShadow = true
    // directionalLight.shadow.camera.top = 180
    // directionalLight.shadow.camera.bottom = -100
    // directionalLight.shadow.camera.left = -120
    // directionalLight.shadow.camera.right = 120
    scene.add(directionalLight)
  }








  window.onresize = function () {

    // camera.aspect = window.innerWidth / window.innerHeight;
    // camera.aspect = width / height;
    // camera.updateProjectionMatrix();

    // renderer.setSize(width, height)
    // renderer.setSize( window.innerWidth, window.innerHeight );

  };


  function animate() {

    if (resizeRendererToDisplaySize(renderer)) {
      const canvas = renderer.domElement;
      camera.aspect = canvas.clientWidth / canvas.clientHeight;
      camera.updateProjectionMatrix();
    }

    requestAnimationFrame( animate );

    const delta = clock.getDelta();

    // mixer.update( delta );

    controls.update();

    // stats.update();


    
    renderer.setClearColor(0xEEEEEE);
    renderer.render( scene, camera );

  }
}