import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { database } from '../../firebase'
import { Box, Stack, Fab, Paper, List, Collapse, Grid, Container, Button, Alert, AlertTitle } from '@mui/material'
import { Link } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group';
import CartItem from '../cart/cartItem';
import OrderItemTable from './OrderItemTable';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import ShippingFieldsReadOnly from '../cart/shippingFieldsReadOnly'
import OrderItem from './OrderItem'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function OrderDetail() {
    const { orderId } = useParams()
    const [order, setOrder] = useState()

    useEffect(() => {
        const unsubscribe = database.orders.doc(orderId)
            .onSnapshot((doc) => {
                setOrder(database.formatDoc(doc))
            });
        return unsubscribe
    }, [])

    return (
        <>
            <Container>

                <Box py={2} px={2}>
                    <Link to={`/orders`}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<ArrowBackIcon />}
                        >
                            Volver
                        </Button>
                    </Link>
                </Box>

                {!order &&
                    <CircularProgress
                        // size={24}
                        sx={{
                            color: "#6622EE",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            // marginTop: '-12px',
                            // marginLeft: '-12px',
                        }}
                    />
                }

                {order &&
                    <Box m={2} py={3}>
                        <Stack spacing={4}>
                            <OrderItem order={order} />
                            <OrderItemTable items={order.items} />

                            <Box sx={{ maxWidth: 'sm' }}>
                                <ShippingFieldsReadOnly shipping={order.shipping} />
                            </Box>
                        </Stack>
                    </Box>
                }
            </Container>
        </>
    )
}
