import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import StarBorderIcon from '@material-ui/icons/StarBorder';

export default function CartItem(props) {
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    function handleDeleteConfirm() {
        setOpen(false)
        props.removeItem(props.item.id)
    }

    function handleClose() {
        setOpen(false)
    }

    return (

        <Paper sx={{ my: 1, py: 1, px: 2, border: `1px solid #e8e8e8`, width: "100%" }}>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Eliminar archivo"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Estás seguro que deseas eliminar el archivo cargado?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus>Si</Button>
                </DialogActions>
            </Dialog>


            <Grid container wrap="nowrap" spacing={1} justifyContent="space-between" alignItems="center">

                <Grid item xs zeroMinWidth>

                    <Stack spacing={1}>
                        <Typography variant="subtitle1" color="text.secondary" component="div" display="inline">
                            {props.item.name}
                        </Typography>

                        <TextField
                            size="small"
                            id="quantity"
                            label="Cantidad"
                            type="number"
                            variant="outlined"
                            inputProps={{ min: 1, max: 99 }}
                            value={props.item.quantity}
                            onChange={(e) => { props.updateQuantity(props.item.id, e.target.value) }} />

                        <Typography noWrap component="div" variant="h5">
                            ${(props.item.selectedOption.cost * props.item.quantity)}
                        </Typography>

                    </Stack>
                </Grid>

                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Stack spacing={1} direction="column">
                            <Chip size="small" icon={<StarBorderIcon />} label={`Calidad ${props.item.selectedOption.label}`} />
                            <Chip size="small" icon={<FormatColorFillIcon />} label={props.item.selectedColor.label} />
                        </Stack>

                        <IconButton size="small" color="secondary" aria-label="add" style={{ outline: 'none' }} onClick={handleClickOpen}>
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                </Grid>

            </Grid>
        </Paper>
    )
}
