import React, { useState, useEffect } from 'react'
import { database, functions } from '../../firebase'
import { useAuth } from '../../context/AuthContext'
import { Link } from 'react-router-dom'
import { Typography, Button, Paper, Box, Container, Snackbar } from '@material-ui/core'
import CircularProgress from '@mui/material/CircularProgress';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import OrderItem from './OrderItem'

export default function AdminOrders() {
    const { currentUser } = useAuth()
    const [orders, setOrders] = useState()

    const [statusList, setStatusList] = useState({
        no_payment: false,
        payment_approved: true,
        payment_in_process: false,
        payment_rejected: false,
        payment_refunded: false,
        files_approved: true,
        printing: true,
        printed: true,
        shipped: false,
        delivered: false
    })

    const [showNoPayment, setShowNoPayment] = useState(false)
    const [showPaymentApproved, setShowPaymentApproved] = useState(true)
    const [showPaymentRejected, setShowPaymentRejected] = useState(false)
    const [showRefundInProcess, setShowRefundInProcess] = useState(true)
    const [showPaymentRefunded, setShowPaymentRefunded] = useState(false)
    const [showFilesApproved, setShowFilesApproved] = useState(true)
    const [showPrinting, setShowPrinting] = useState(true)
    const [showPrinted, setShowPrinted] = useState(true)
    const [showShipped, setShowShipped] = useState(true)
    const [showDelivered, setShowDelivered] = useState(false)

    useEffect(() => {
        let selectedStatus = []
        if (showNoPayment) {
            selectedStatus.push('no_payment')
        }

        if (showPaymentApproved) {
            selectedStatus.push('payment_approved')
        }

        if (showRefundInProcess) {
            selectedStatus.push('refund_in_process')
        }

        if (showPaymentRejected) {
            selectedStatus.push('payment_rejected')
        }

        if (showPaymentRefunded) {
            selectedStatus.push('payment_refunded')
        }

        if (showFilesApproved) {
            selectedStatus.push('files_approved')
        }

        if (showPrinting) {
            selectedStatus.push('printing')
        }

        if (showPrinted) {
            selectedStatus.push('printed')
        }

        if (showShipped) {
            selectedStatus.push('shipped')
        }
        if (showDelivered) {
            selectedStatus.push('delivered')
        }

        if (selectedStatus.length == 0) {
            selectedStatus.push('')
        }

        console.log("selectedStatus: ", selectedStatus)

        return database.orders
            .where('status', 'in', selectedStatus)
            .orderBy("timestamp", "desc")
            .onSnapshot(snapshot => {
                setOrders(snapshot.docs.map(database.formatDoc))
            })
    }, [
        showNoPayment,
        showPaymentApproved,
        showRefundInProcess,
        showPaymentRejected,
        showPaymentRefunded,
        showFilesApproved,
        showPrinting,
        showPrinted,
        showShipped,
        showDelivered
    ])

    return (
        <>
            <Container
                style={{ border: '8px solid #eb4034' }}
            >

                <Link to={`/admin/payments`}>
                    <Button
                        variant="contained"
                        color="primary"
                    >
                        Buscar Pagos
                    </Button>
                </Link>

                {!orders &&
                    <CircularProgress
                        sx={{
                            color: "#6622EE",
                            position: 'absolute',
                            top: '50%',
                            left: '50%'
                        }}
                    />
                }

                {orders &&
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={showNoPayment} onChange={(e) => {
                            setShowNoPayment(e.target.checked)
                        }} />} label="SIN PAGO" />

                        <FormControlLabel control={<Checkbox checked={showPaymentApproved} onChange={(e) => {
                            setShowPaymentApproved(e.target.checked)
                        }} />} label="Pago Aprobado" />

                        <FormControlLabel control={<Checkbox checked={showPaymentRejected} onChange={(e) => {
                            setShowPaymentRejected(e.target.checked)
                        }} />} label="Pago Rechazado" />

                        <FormControlLabel control={<Checkbox checked={showRefundInProcess} onChange={(e) => {
                            setShowRefundInProcess(e.target.checked)
                        }} />} label="Devolucion En Proceso" />

                        <FormControlLabel control={<Checkbox checked={showPaymentRefunded} onChange={(e) => {
                            setShowPaymentRefunded(e.target.checked)
                        }} />} label="Pago Devuelto" />

                        <FormControlLabel control={<Checkbox checked={showFilesApproved} onChange={(e) => {
                            setShowFilesApproved(e.target.checked)
                        }} />} label="Archivos Aprobados" />

                        <FormControlLabel control={<Checkbox checked={showPrinting} onChange={(e) => {
                            setShowPrinting(e.target.checked)
                        }} />} label="Imprimiendo" />

                        <FormControlLabel control={<Checkbox checked={showPrinted} onChange={(e) => {
                            setShowPrinted(e.target.checked)
                        }} />} label="Impreso" />

                        <FormControlLabel control={<Checkbox checked={showShipped} onChange={(e) => {
                            setShowShipped(e.target.checked)
                        }} />} label="Enviado" />

                        <FormControlLabel control={<Checkbox checked={showDelivered} onChange={(e) => {
                            setShowDelivered(e.target.checked)
                        }} />} label="Entregado" />

                    </FormGroup>
                }

                {orders && orders.map(order => (
                    <Box key={order.id} py={1}>
                        <Paper>
                            <Box py={2} px={2}>
                                <OrderItem order={order} />
                                <Link to={`/admin/${order.id}`}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                    >
                                        Ver Detalle
                                    </Button>
                                </Link>
                            </Box>

                        </Paper>
                    </Box>
                ))
                }
            </Container >
        </>
    )
}
