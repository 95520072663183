import React, { useEffect } from 'react'
import Stl from './stl'

export const StlViewer = ({
  width,
  height,
  url,
  objectColor,
  gridLineColor,
  skyboxColor,
  groundColor,
  lightColor,
  modelDimension
}) => {
  useEffect(() => {
    Stl(
      width,
      height,
      url,
      objectColor,
      gridLineColor,
      skyboxColor,
      groundColor,
      lightColor,
      modelDimension
    )
  }, [url])
  // return <canvas id="stlviewer"></canvas>
  return <canvas id='stlviewer' style={{
    width: "100%",
    height: "100%",
    display: "block"
  }}></canvas>
}