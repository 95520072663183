import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import { Link } from 'react-router-dom'


export default function OrderItemTable(props) {

    function linkTapped(url) {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Producto</TableCell>
                        <TableCell align="center">Color</TableCell>
                        <TableCell align="center">Calidad</TableCell>
                        <TableCell align="center">Precio</TableCell>
                        <TableCell align="center">Cantidad</TableCell>
                        <TableCell align="center">Subtotal</TableCell>
                        {props.admin && <TableCell align="center">Link</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.items.map((item) => (
                        <TableRow
                            key={item.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {item.name}
                            </TableCell>
                            <TableCell align="center">
                                <Chip size="small" icon={<FormatColorFillIcon />} label={`${item.selectedColor.label}`} />
                            </TableCell>
                            <TableCell align="center">
                                <Chip size="small" icon={<StarBorderIcon />} label={`${item.selectedOption.label}`} />
                            </TableCell>
                            <TableCell align="center">${item.selectedOption.cost}</TableCell>
                            <TableCell align="center">
                                {item.quantity}
                            </TableCell>
                            <TableCell align="center">${item.selectedOption.cost * item.quantity}</TableCell>
                            {props.admin &&
                                <TableCell align="center">
                                    <Button
                                        variant="contained"
                                        onClick={() => { linkTapped(item.downloadUrl) }}
                                    >
                                        Descargar
                                    </Button>
                                </TableCell>



                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}