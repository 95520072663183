import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext'
import CenteredContainer from "./CenteredContainer";

export default function UpdateProfile() {
    const emailRef = useRef()
    const usernameRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { currentUser, updateEmail, updatePassword, updateDisplayName } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    function handleSubmit(e) {
        e.preventDefault()

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Las contraseñas no coinciden')
        }

        const promises = []
        setLoading(true)
        setError('')
        if (emailRef.current.value !== currentUser.email) {
            promises.push(updateEmail(emailRef.current.value))
        }

        if (passwordRef.current.value) {
            promises.push(updatePassword(passwordRef.current.value))
        }

        if (usernameRef.current.value) {
            promises.push(updateDisplayName(usernameRef.current.value))
        }

        Promise.all(promises).then(() => {
            history.push("/profile")
        }).catch(() => {
            setError('Error al actualizar el perfil')
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <CenteredContainer>
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">Actualizar Perfil</h2>
                    {error && <Alert variant="danger" >{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="username">
                            <Form.Label>Nombre de usuario</Form.Label>
                            <Form.Control type="text" ref={usernameRef} required defaultValue={currentUser.displayName} />
                        </Form.Group>
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" ref={emailRef} required defaultValue={currentUser.email} />
                        </Form.Group>
                        <Form.Group id="password">
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control type="password" ref={passwordRef} placeholder='Dejar en blanco para mentener actual' />
                        </Form.Group>
                        <Form.Group id="password-confirm">
                            <Form.Label>Repetir Contraseña</Form.Label>
                            <Form.Control type="password" ref={passwordConfirmRef} placeholder='Dejar en blanco para mentener actual' />
                        </Form.Group>
                        <Button disabled={loading} className="w-100" type="submit">Actualizar</Button>
                    </Form>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                <Link to="/profile" >Cancelar</Link>
            </div>
        </CenteredContainer>
    )
}
