import React, { useRef, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useHistory } from 'react-router-dom';
import { TextField, Typography, Button } from '@material-ui/core'
import { Stack, Snackbar, Alert } from '@mui/material'
import CenteredContainer from "./CenteredContainer";
import { database } from '../../firebase';

export default function Signup() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { signup } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    async function handleSubmit(event) {
        event.preventDefault();
        setError('')
        setLoading(true)

        try {
            const cred = await signup(email, password)

            await database.users.doc(cred.user.uid).set({
                email: email,
                displayName: username,
                registered: new Date()
            })

            cred.user.sendEmailVerification();
            cred.user.updateProfile({
                displayName: username
            });
            history.push("/")

        } catch (error) {
            setError(error.message)
        }

        setLoading(false)
    }

    return (
        <CenteredContainer>

            <Snackbar
                open={error}
                autoHideDuration={3000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>

            <form onSubmit={handleSubmit} >

                <Stack spacing={4} direction="column">

                    <Stack spacing={2} direction="column">
                        <TextField
                            fullWidth
                            required
                            label='Nombre de usuario'
                            placeholder='Ingresar nombre de usuario'
                            type="text"
                            id="username"
                            value={username}
                            onInput={e => setUsername(e.target.value)}
                        />

                        <TextField
                            fullWidth
                            required
                            label='Email'
                            placeholder='Ingresar email'
                            type="email"
                            id="email"
                            value={email}
                            onInput={e => setEmail(e.target.value)}
                        />

                        <TextField
                            fullWidth
                            required
                            label='Contraseña'
                            placeholder='Ingresar contraseña'
                            type='password'
                            id="password"
                            value={password}
                            onInput={e => setPassword(e.target.value)}
                        />
                    </Stack>

                    <Button disabled={loading} type='submit' color='primary' variant="contained">
                        {loading ? "Creando cuenta..." : "Crear cuenta"}
                    </Button>

                    <Typography align='center'>
                        ¿Ya estas registrado? <Link style={{ color: '#6622EE' }} to="/login">Ingresa aqui</Link>
                    </Typography>

                </Stack>
            </form>
        </CenteredContainer >
    )
}
