
import { createTheme } from '@material-ui/core/styles';

const theme =
  createTheme({
    spacing: 8,
    props: {
      // MuiContainer: {
      //   maxWidth: "lg"
      // }
    },
    palette: {
      primary: {
        main: '#6622EE',
      },
      secondary: {
        main: '#42364C'
      },
      text: {
        primary: "#000",
        secondary: "#42364C",
        disabled: "#C9C9C9"
      },
      neutral: {
        main: '#64748B',
        contrastText: '#fff',
      }
    },

    typography: {
      button: {
        textTransform: 'none'
      },
      // fontFamily: 'Exo',
      fontSize: 14,
      // h1: {
      //   fontWeight: 700,
      //   fontSize: "6.8rem"
      // },
      // h2: {
      //   fontSize: "4.2rem",
      //   fontWeight: 700,
      //   '@media (max-width:600px)': {
      //     fontSize: '3.4rem',
      //   },
      // },
      // h3: {
      //   fontSize: "3.4rem",
      //   fontWeight: 700,
      //   '@media (max-width:600px)': {
      //     fontSize: '2.8rem',
      //   },
      // },
      // h4: {
      //   fontSize: "2.4rem",
      //   fontWeight: 400,
      // },
      // h5: {
      //   fontSize: "1.7rem",
      //   fontWeight: 400,
      // },
      // h6: {
      //   fontSize: "1.4rem",
      //   fontWeight: 400,
      //   '@media (max-width:600px)': {
      //     fontSize: '0.8rem',
      //   },
      // },
      subtitle1: {
        fontSize: "1.15rem",
        fontWeight: 400,
      },
      subtitle2: {
        fontSize: "1rem",
        fontWeight: 400,
      },
      body1: {
        fontSize: "0.8rem",
        fontWeight: 400,

      },
      body2: {
        fontSize: "0.8rem",
        fontWeight: 400,
      },



    },
  },
  );

export default theme