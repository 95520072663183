import React, { useState } from 'react'
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link, useHistory } from 'react-router-dom'
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../context/AuthContext'
import { useCart } from '../../context/CartContext'
import { Stack, Drawer, Badge } from '@mui/material'
import CartDrawerContent from '../cart/cartDrawerContent'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Button, IconButton } from '@material-ui/core'
import { styled, alpha } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function NavbarMenu(props) {
    const { currentUser, isAdminUser, logout } = useAuth()
    const { currentCart } = useCart()

    const history = useHistory()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [error, setError] = useState('')
    const [dialogOpen, setDialogOpen] = useState(false);

    function handleClick(event) {
        setAnchorEl(event.currentTarget)
    }

    function handleClose() {
        setAnchorEl(null)
    }

    function handleLogout() {
        setDialogOpen(true);
    }

    function handleDialogClose() {
        setDialogOpen(false)
    }

    async function handleLogOutConfirm() {
        setDialogOpen(false)
        setError('')
        try {
            await logout()
            history.push('/login')
        } catch (error) {
            setError(error.message)
        }
    }

    const [drawerOpen, setDrawerOpen] = useState(false)

    function toggleDrawer() {
        setDrawerOpen(!drawerOpen)
    }

    function closeDrawer() {
        setDrawerOpen(false)
    }

    return (
        <Stack direction="row" spacing={2}>
            <Drawer
                anchor="right"
                variant="temporary"
                open={drawerOpen}
                onClose={closeDrawer}
            >
                <CartDrawerContent toggleDrawer={toggleDrawer} drawerOpen={drawerOpen} />
            </Drawer>

            <div>
                <Button
                    color="primary"
                    id="demo-customized-button"
                    aria-controls="demo-customized-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    style={{ outline: "none" }}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    Menu
                </Button>
            </div>

            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >

                <MenuList>
                    <Link to="/profile">
                        <MenuItem href="link/to/some/page">
                            <ListItemIcon>
                                <PersonIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Perfil</ListItemText>
                        </MenuItem>
                    </Link>
                    <Link to="/orders">
                        <MenuItem>
                            <ListItemIcon>
                                <AssignmentIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Ordenes</ListItemText>
                        </MenuItem>
                    </Link>
                    <Divider />
                    <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Cerrar Sesión</ListItemText>
                    </MenuItem>

                    {isAdminUser &&
                        <Link to="/admin">
                            <MenuItem>
                                <ListItemIcon>
                                    <AdminPanelSettingsIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText><strong>ADMIN</strong></ListItemText>
                            </MenuItem>
                        </Link>
                    }
                </MenuList>

            </StyledMenu>

            <IconButton aria-label="cart" color="primary" onClick={() => toggleDrawer(!drawerOpen)} style={{ backgroundColor: "#6622EE", outline: "none", color: "#ffffff" }} >
                <Badge color="secondary" badgeContent={currentCart ? currentCart.length : null} >
                    <ShoppingCartIcon />
                </Badge>
            </IconButton>

            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Cerrar Sesión"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Estás seguro que deseas cerrar sesión?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} style={{ outline: "none" }}>No</Button>
                    <Button onClick={handleLogOutConfirm} autoFocus style={{ outline: "none" }}>Si</Button>
                </DialogActions>
            </Dialog>

        </Stack>
    )
}
