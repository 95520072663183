import React, { useState, useEffect } from 'react'
import { database, functions } from '../../firebase'
import { TextField, Button, Paper, Box, Container, Snackbar } from '@material-ui/core'

export default function AdminPayments() {
    const [paymentId, setPaymentId] = useState()
    const [payment, setPayment] = useState()

    function search() {
        if (!paymentId) { return }
        functions.httpsCallable('getPaymentDetails')({
            paymentId: paymentId
        })
            .then((res) => {
                setPayment(JSON.stringify(res.data.payment))
            })
            .catch(error => {
                console.log("payment error: ", error.message)
            })
    }

    return (
        <>
            <Container
                style={{ border: '8px solid #eb4034' }}
            >
                <TextField
                    label="Payment ID"
                    variant="outlined"
                    value={paymentId}
                    onChange={e => setPaymentId(e.target.value)}
                />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={search}
                >
                    Buscar
                </Button>

                {payment &&
                    <p>
                        {payment}
                    </p>
                }

            </Container >
        </>
    )
}
