import React, { useRef, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link } from 'react-router-dom';
import { TextField, Typography, Button } from '@material-ui/core'
import CenteredContainer from "./CenteredContainer";
import { Stack, Snackbar, Alert } from '@mui/material'

export default function ForgotPassword() {
    const [email, setEmail] = useState('');
    const { resetPassword } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [didSend, setDidSend] = useState(false)
    const [message, setMessage] = useState('')

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setMessage('')
            setError('')
            setLoading(true)
            await resetPassword(email)
            setDidSend(true)
            setMessage('Te enviamos un email para recuperar tu contraseña')
        } catch (error) {
            setError(error.message)
        }

        setLoading(false)
    }

    return (
        <CenteredContainer>

            <Snackbar
                open={error}
                autoHideDuration={3000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>

            <form onSubmit={handleSubmit} >

                <Stack spacing={4} direction="column">

                    <Stack spacing={2} direction="column">
                        <Typography variant="h5" align='center'>
                            Reestablecer Contraseña
                        </Typography>
                    </Stack>

                    <TextField
                        fullWidth
                        required
                        label='Email'
                        placeholder='Ingresar email'
                        type="email"
                        id="email"
                        value={email}
                        onInput={e => setEmail(e.target.value)}
                    />

                    {message &&
                        <Alert
                            severity="success"
                            sx={{ width: '100%' }}
                            action={
                                <Link style={{ color: '#6622EE' }} to="/login">
                                    <Button color="inherit" size="small" >
                                        Iniciar Sesión
                                    </Button>
                                </Link>
                            }
                        >
                            {message}
                        </Alert>
                    }

                    <Button disabled={loading || didSend} type='submit' color='primary' variant="contained">
                        {loading ? "Enviando..." : didSend ? "Enviado!" : "Enviar"}
                    </Button>

                    <Typography align='center'>
                        ¿Sos nuevo? <Link style={{ color: '#6622EE' }} to="/signup">Creá una cuenta aquí</Link>
                    </Typography>

                </Stack>
            </form>
        </CenteredContainer >
    )
}