import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';


export default function emptyState(props) {
    if (props.loading) {
        return (
            <Stack direction="column" alignItems="center" spacing={2}>
                <CircularProgress color="primary" sx={{
                    color: "#6622EE"
                }} />
                <Typography variant="h3" >Calculando...</Typography>
            </Stack>
        )
    } else {
        return (
            <Grid item container direction="column" justifyContent="center" >
                <Grid item>
                    <Box sx={{ mb: 1 }}>
                        <Typography variant="h3" color="primary">Dale vida a tus ideas</Typography>
                    </Box>
                </Grid>

                <Grid item>
                    <Typography variant="h4">Cotizá y creá tu pedido</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="h6">
                        Subí tu modelo en 3D, configurá la impresión
                    </Typography>
                    <Typography variant="h6">
                        y nosotros ¡nos encargamos del resto!
                    </Typography>
                </Grid>

                {/* <Grid item>
                    <Box sx={{ mt: 2 }}>
                        <Button variant="outlined" color="secondary">Video Explicativo</Button>
                    </Box>
                </Grid> */}
            </Grid>
        )
    }
}
