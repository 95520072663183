import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'

export default function PublicRoute( { component: Component, ...rest }) {
    const { currentUser } = useAuth()

    const location = useLocation();

    React.useEffect(() => {
        window.fbq('track', 'PageView')
        console.log('Location changed');
    }, [location]);

    return (
        <Route 
         {...rest}
         render={props => {
            return !currentUser ? <Component {...props} /> : <Redirect to="/"/>
         }}
        >
         </Route>
    )
}
