import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { Link, Box, Stack, Fab, Paper, List, Collapse, Grid, Container, Alert } from '@mui/material'
import { TransitionGroup } from 'react-transition-group';
import ShippingFields from '../cart/shippingFields'
import { useAuth } from '../../context/AuthContext'
import { useCart } from '../../context/CartContext'
import { database, storage, functions } from '../../firebase'
import CartItem from './cartItem';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@material-ui/core'
import CircularProgress from '@mui/material/CircularProgress';
import './cartDrawerContent.css';

function Footer(props) {
    return (
        <Stack spacing={1}>

            <Typography component="div" variant="h6">
                Envio: ${props.shippingCost > 0 ? props.shippingCost : "-"}
            </Typography>
            <Typography component="div" variant="h5">
                Total: ${props.items.map(item => {
                    return item.selectedOption.cost * item.quantity
                }).reduce((previousValue, currentValue) => previousValue + currentValue) + (props.shippingCost)}
            </Typography>

            <Button
                variant="contained"
                style={{
                    width: "100%",
                    backgroundColor: '#439ddd',
                    color: '#FFFFFF'
                }}
                disabled={props.loading}
                onClick={props.createPreference}
            >
                <strong>{props.loading ? "Cargando..." : "Pagar con Mercado Pago"}</strong>
            </Button>

        </Stack>
    )
}

export default function CartDrawerContent(props) {
    const { currentCart, removeItemAndFile, updateItemQuantity } = useCart()
    const { currentUser } = useAuth()
    const [loading, setLoading] = useState(false)
    const [shipping, setShipping] = useState({})
    const [error, setError] = useState()

    async function handleRemoveItem(itemId) {
        try {
            setError('')
            await removeItemAndFile(itemId)
        } catch (error) {
            setError(error.message)
        }
    }

    async function handleUpdateItemQuantity(itemId, qty) {
        try {
            setError('')
            await updateItemQuantity(itemId, qty)
        } catch (error) {
            setError(error.message)
        }
    }

    function handleAddressChange(shipping) {
        setError('')
        setShipping(shipping)
    }

    function createPreference() {
        setError('')
        setLoading(true)

        var createOrder = functions.httpsCallable('createOrder')

        createOrder({
            shipping: shipping
        })
            .then(result => {
                setLoading(false)

                const mercado = new window.mercado(process.env.REACT_APP_MERCADO_PAGO_KEY, {
                    locale: 'es-AR'
                });

                window.fbq('track', 'InitiateCheckout', {
                    preferenceId: result.data.preferenceId
                });

                mercado.checkout({
                    preference: {
                        id: result.data.preferenceId
                    },
                    autoOpen: true
                    // sandbox: true
                })
            })
            .catch(error => {
                window.fbq('trackCustom', 'InitiateCheckoutFailed', {
                    error: error.message
                });
                setError(error.message)
                setLoading(false)
            })
    }

    return (


        <div className="container">
            <div className="items">

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid>
                        <IconButton aria-label="cart" color="primary" onClick={() => props.toggleDrawer(!props.drawerOpen)} style={{ backgroundColor: "#6622EE", outline: "none", color: "#ffffff" }} >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid>
                        <Typography variant="h6"> {currentCart ? `${currentCart.length} item/s` : "Carro vacío"}</Typography>
                    </Grid>
                </Grid>

                <List style={{
                    overflow: 'auto',
                    marginBottom: "4%",
                    marginTop: "4%"
                }}>

                    <TransitionGroup>
                        {currentCart && currentCart.map(item =>
                        (
                            <Collapse key={item.id}>
                                <Box>
                                    <CartItem
                                        item={item}
                                        removeItem={handleRemoveItem}
                                        updateQuantity={handleUpdateItemQuantity}
                                    />
                                </Box>
                            </Collapse>
                        ))}

                    </TransitionGroup>

                </List>
            </div>

            <div className="shipping">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Datos de Envío</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ShippingFields shipping={shipping} handleAddressChange={handleAddressChange} />
                    </AccordionDetails>
                </Accordion>

            </div >

            <div className="paymentFooter">

                <Stack spacing={2}>
                    {error ? <Alert severity="error">{error}</Alert> : null}

                    {currentCart && currentCart.length != 0 ?
                        <Footer items={currentCart} createPreference={createPreference} loading={loading} shippingCost={shipping.cost ?? 0} />
                        : null}
                </Stack>
            </div>
        </div >

    )
}








