import React from 'react'
import { useState, useEffect } from 'react'
import { TextField, MenuItem } from '@material-ui/core'
import { Stack, Snackbar, Alert } from '@mui/material'
import Typography from '@mui/material/Typography';

export default function ShippingFields(props) {

    const [recipientName, setRecipientName] = useState(null)
    const [streetName, setStreetName] = useState(null)
    const [streetNumber, setStreetNumber] = useState(null)
    const [district, setDistrict] = useState(null)
    const [floor, setFloor] = useState(null)
    const [unit, setUnit] = useState(null)
    const [city, setCity] = useState(null)
    const [zipcode, setZipcode] = useState(null)
    const [extraInfo, setExtraInfo] = useState(null)

    const errors = props.errors ?? {}

    function getShippingCost() {
        switch (city) {
            case "CABA":
                return 200
            case "GBA":
                return 400
            default:
                return 0
        }
    }

    function handleAddressChange() {
        if (!props.handleAddressChange) { return }
        props.handleAddressChange({
            name: recipientName,
            cost: getShippingCost(),
            streetName: streetName,
            streetNumber: streetNumber,
            floor: floor,
            apartment: unit,
            district: district,
            zipcode: zipcode,
            cityName: city,
            stateName: "Buenos Aires",
            extraInfo: extraInfo
        })
    }

    useEffect(() => {
        handleAddressChange()
    }, [recipientName, streetName, streetNumber, district, floor, unit, city, zipcode])

    const cities = [
        {
            value: '',
            label: 'Seleccionar',
        },
        {
            value: 'CABA',
            label: 'CABA - $200',
        },
        {
            value: 'GBA',
            label: 'GBA - $400',
        }
    ];


    return (
        // <form onSubmit={handleSubmit} >

        <Stack spacing={2} direction="column">

            <TextField
                fullWidth
                required
                id="recipientName"
                label="Nombre"
                variant="outlined"
                size="small"
                value={recipientName}
                onChange={(e) => setRecipientName(e.target.value)}
                error={errors["recipientName"]}
                helperText={errors["recipientName"]}
            />

            <Stack spacing={2} direction="row">
                <TextField
                    fullWidth
                    required
                    id="streetName"
                    label="Calle"
                    variant="outlined"
                    size="small"
                    value={streetName}
                    onChange={(e) => setStreetName(e.target.value)}
                    error={errors["streetName"]}
                    helperText={errors["streetName"]}
                />

                <TextField
                    fullWidth
                    required
                    id="streetNumber"
                    label="Altura"
                    variant="outlined"
                    size="small"
                    value={streetNumber}
                    onChange={(e) => setStreetNumber(e.target.value)}
                    error={errors["streetNumber"]}
                    helperText={errors["streetNumber"]}
                />
            </Stack>

            <Stack spacing={2} direction="row">
                <TextField
                    fullWidth
                    id="floor"
                    label="Piso"
                    variant="outlined"
                    size="small"
                    value={floor}
                    onChange={(e) => setFloor(e.target.value)}
                />

                <TextField
                    fullWidth
                    id="unit"
                    label="Unidad"
                    variant="outlined"
                    size="small"
                    value={unit}
                    onChange={(e) => setUnit(e.target.value)}
                />
            </Stack>

            <Stack spacing={2} direction="row">
                <TextField
                    fullWidth
                    required
                    id="district"
                    label="Barrio"
                    variant="outlined"
                    size="small"
                    value={district}
                    onChange={(e) => setDistrict(e.target.value)}
                    error={errors["district"]}
                    helperText={errors["district"]}
                />

                <TextField
                    fullWidth
                    required
                    id="zipcode"
                    label="Codigo Postal"
                    variant="outlined"
                    size="small"
                    value={zipcode}
                    onChange={(e) => setZipcode(e.target.value)}
                    error={errors["zipcode"]}
                    helperText={errors["zipcode"]}
                />
            </Stack>

            <TextField
                fullWidth
                multiline
                id="extraInfo"
                label="Información Adicional"
                variant="outlined"
                size="small"
                value={extraInfo}
                onChange={(e) => setExtraInfo(e.target.value)}
                error={errors["extraInfo"]}
                helperText={errors["extraInfo"]}
            />

            <TextField
                fullWidth
                id="city"
                select
                placeholder="Localidad"
                value={city}
                variant="outlined"
                size="small"
                onChange={(e) => setCity(e.target.value)}
                SelectProps={{
                    native: true,
                }}
            >
                {cities.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </TextField>


        </Stack>
        // </form>

    )
}
