import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'

export default function PublicRoute({ component: Component, ...rest }) {

    const location = useLocation();

    React.useEffect(() => {
        window.fbq('track', 'PageView')
        console.log('Location changed');
    }, [location]);

    return (
        <Route
            {...rest}
            render={props => {
                return <Component {...props} />
            }}
        >
        </Route>
    )
}
