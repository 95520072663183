import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { database, functions } from '../../firebase'
import { Box, Stack, Fab, Paper, List, Collapse, Grid, Container, Button, Alert, Snackbar } from '@mui/material'
import { Link } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group';
import CartItem from '../cart/cartItem';
import OrderItemTable from './OrderItemTable';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import ShippingFieldsReadOnly from '../cart/shippingFieldsReadOnly'
import OrderItem from './OrderItem'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function AdminOrderDetail() {
    const { orderId } = useParams()
    const [order, setOrder] = useState()
    const [message, setMessage] = useState(null)
    const [error, setError] = useState(null)
    const [disabled, setDisabled] = useState(false)

    function cancelOrder(orderId) {
        setDisabled(true)
        functions.httpsCallable('refundPayment')({
            orderId: orderId
        })
            .then(() => {
                setDisabled(false)
                setMessage(`Pago devuelto!`)
            })
            .catch(error => {
                setDisabled(false)
                setError(error.message)
            })
    }

    function updateStatus(status) {
        setDisabled(true)
        functions.httpsCallable('updateOrderStatus')({
            orderId: orderId,
            status: status
        })
            .then(() => {
                setDisabled(false)
                setMessage(`Estado actualizado a: ${status}`)
            })
            .catch(error => {
                setDisabled(false)
                setError(error.message)
            })
    }

    useEffect(() => {
        const unsubscribe = database.orders.doc(orderId)
            .onSnapshot((doc) => {
                setOrder(database.formatDoc(doc))
            });
        return unsubscribe
    }, [])

    return (
        <>
            <Container
                style={{ border: '8px solid #eb4034' }}
            >

                <Snackbar
                    open={error}
                    autoHideDuration={3000}
                    onClose={() => { setError(null) }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}>
                    <Alert severity="error">
                        {error}
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={message}
                    autoHideDuration={3000}
                    onClose={() => { setMessage(null) }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}>
                    <Alert severity="success">
                        {message}
                    </Alert>
                </Snackbar>

                <Box py={2} px={2}>
                    <Link to={`/admin`}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<ArrowBackIcon />}
                        >
                            Volver
                        </Button>
                    </Link>
                </Box>

                {!order &&
                    <CircularProgress
                        // size={24}
                        sx={{
                            color: "#6622EE",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            // marginTop: '-12px',
                            // marginLeft: '-12px',
                        }}
                    />
                }

                {order &&
                    <Box m={2} py={3}>
                        <Stack spacing={4}>

                            <Stack>
                                <Button
                                    disabled={disabled}
                                    onClick={() => { updateStatus("files_approved") }}
                                    variant="contained"
                                    color="primary"
                                >
                                    Aprobar archivos
                                </Button>

                                <Button
                                    disabled={disabled}
                                    onClick={() => { updateStatus("printing") }}
                                    variant="contained"
                                    color="primary"
                                >
                                    Imprimiendo
                                </Button>

                                <Button
                                    disabled={disabled}
                                    onClick={() => { updateStatus("printed") }}
                                    variant="contained"
                                    color="primary"
                                >
                                    Impreso
                                </Button>

                                <Button
                                    disabled={disabled}
                                    onClick={() => { updateStatus("shipped") }}
                                    variant="contained"
                                    color="primary"
                                >
                                    Enviado
                                </Button>

                                <Button
                                    disabled={disabled}
                                    onClick={() => { updateStatus("delivered") }}
                                    variant="contained"
                                    color="primary"
                                >
                                    Entregado
                                </Button>

                                <Button
                                    disabled={disabled}
                                    onClick={() => { cancelOrder(order.id) }}
                                    variant="contained"
                                    style={{
                                        backgroundColor: '#eb4034',
                                        color: '#FFFFFF'
                                    }}
                                    color="primary"
                                >
                                    Rechazar y devolver pago
                                </Button>
                            </Stack>


                            <OrderItem order={order} />
                            <OrderItemTable items={order.items} admin={true} />

                            <h4>ID de pago: {order.paymentId}</h4>

                            <Box sx={{ maxWidth: 'sm' }}>
                                <ShippingFieldsReadOnly shipping={order.shipping} />
                            </Box>
                        </Stack>
                    </Box>
                }
            </Container>
        </>
    )
}
