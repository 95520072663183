import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@mui/material/Skeleton';
import { CirclePicker } from 'react-color';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function ChipColorPicker(props) {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            rowSpacing={1} columnSpacing={1}
        >
            {props.colors.map((option, index) => {
                return <Grid item key={index}>
                    <Chip
                        key={index}
                        variant="outlined"
                        size={option != props.selectedColor ? "small" : ""}
                        label={option.label}
                        onClick={() => props.onChange(option)}
                        style={{ backgroundColor: option.hex, color: option.labelHex, borderWidth: option == props.selectedColor ? 2 : 1 }}
                    />
                </Grid>
            })}
        </Grid>
    )
}

export default function configurationOptions(props) {
    const loading = true
    return (
        <Stack spacing={4} >
            <TextField
                disabled={props.uploading}
                id="file-name"
                label="Nombre"
                variant="outlined"
                value={props.fileName}
                onChange={props.fileNameChange}
                error={!props.fileNameValid}
                helperText={props.fileNameValid ? "" : "Nombre inválido"}
            />

            <Stack spacing={2}>
                <Box>
                    <Typography variant="h5">Elegí la resolución de impresión</Typography>
                </Box>

                <Stack direction="row" alignItems="center">

                    <Box mr={2}>
                        <StarBorderIcon />
                    </Box>
                    {props.resolutionOptions.map((option, index) => {
                        return <Box mr={2} key={index}>
                            <Tooltip title={`$${option.cost}`} placement="top" arrow>
                                <Button
                                    variant={props.selectedOption === option ? "contained" : "outlined"}
                                    color="primary"
                                    onClick={() => {
                                        props.onOptionChange(option)
                                    }}
                                    disabled={props.uploading}
                                >
                                    {option.label}
                                </Button>
                            </Tooltip>
                        </Box>
                    })}
                </Stack>

                <Stack direction="row" alignItems="center">
                    <Box mr={2}>
                        <FormatColorFillIcon />
                    </Box>

                    <ChipColorPicker
                        colors={props.colorOptions}
                        selectedColor={props.selectedColor}
                        onChange={(color) => !props.uploading ? props.colorChange(color) : null}
                    />

                    {/* <CirclePicker
                        colors={props.colorOptions.map(color => { return color.hex })}
                        color={props.selectedColor.hex}
                        onChange={(color) => !props.uploading ? props.colorChange(color) : null}
                    /> */}
                </Stack>

            </Stack>

            <Typography mt={2} variant="h4">${props.selectedOption.cost}</Typography>

            <Stack spacing={2}>

                <TextField
                    size="small"
                    id="quantity"
                    label="Cantidad"
                    type="number"
                    variant="outlined"
                    disabled={props.uploading}
                    inputProps={{ min: 1, max: 99 }}
                    value={props.quantity}
                    onChange={(e) => { props.quantityChange(e.target.value) }} />


                <Stack spacing={1}>
                    {props.tooltipText ? <Alert severity="info">{props.tooltipText}</Alert> : null}
                    <Button variant="contained" color="primary" disabled={!props.enabled} onClick={props.addToCart} style={{ width: "100%", height: "100%" }}>{props.uploading ? "Agregando..." : "Agregar al carro"}</Button>
                </Stack>

                {props.uploading ?
                    <LinearProgressWithLabel value={props.uploadProgress} />
                    : null
                }

            </Stack>

        </Stack>
    )
}
