import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box'
import './cotizador.css'
import { useState } from 'react'
import DropZone from '../../components/dropZone/dropZone'
import { database, storage, functions, analytics } from '../../firebase'
import { StlViewer } from '../../components/stlViewer/stlViewer'
import EmptyState from '../../components/emptyState'
import ConfigurationOptions from '../../components/configurationOptions'
import ModelDimensions from '../../components/modelDimensions'
import Container from '@material-ui/core/Container'
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import { CircularProgress, Fab, Snackbar, Alert } from '@mui/material';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { useAuth } from '../../context/AuthContext'
import { v4 as uuidV4 } from 'uuid'
import { makeStyles } from '@material-ui/core/styles';

export default function Cotizador() {
    const [uploadedFile, setUploadedFile] = useState(null)
    const [fileDimensions, setFileDimensions] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [resolutionOptions, setResolutionsOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState(null)
    const [colorOptions, setColorOptions] = useState([])
    const [selectedColor, setSelectedColor] = useState(null)
    const [uploadProgress, setUploadProgress] = useState(0)
    const [url, setURL] = useState(null)
    const [qty, setQty] = useState(1);
    const [uploading, setUploading] = useState(false)

    const [message, setMessage] = useState(null)

    const { currentUser } = useAuth()

    const useStyles = makeStyles(theme => ({
        fab: {
            position: 'fixed',
            bottom: theme.spacing(-2),
            right: theme.spacing(0),
        },
    }));

    function handleColorChange(color) {
        if (color == null) return
        const colorOptionIndex = colorOptions.map(function (e) { return e.hex.toUpperCase(); }).indexOf(color.hex.toUpperCase());
        setSelectedColor(colorOptions[colorOptionIndex])
    }

    function handleNameChange(event) {
        setFileName(event.target.value)
    }

    function handleFileUploaded(file) {
        window.fbq('trackCustom', 'FileUploaded', {
            file_size: (file.size / (1024*1024))
        });
        analytics.logEvent('FileUploaded', {
            file_size: (file.size / (1024*1024))
        });
        const cleanFileName = file.name.replace('.stl', '');
        setFileName(cleanFileName)
        setURL(URL.createObjectURL(file))
        setUploadedFile(file)
    }

    function handleSelectedOption(option) {
        setSelectedOption(option)
    }

    function validMaxDimensions(dimensions) {
        return (dimensions.x <= 250 && dimensions.x <= 250 && dimensions.x <= 250)
    }

    function handleModelDimension(dimensions) {
        if (validMaxDimensions(dimensions)) {
            setFileDimensions(dimensions)
            getObjectCost(dimensions.volume)
        } else {
            setMessage({
                severity: "error",
                message: "Las dimensiones maximas son 250x250x250 (mm)"
            })
            handleDelete()
        }
    }

    function handleDelete() {
        setURL(null)
        setUploadedFile(null)
        setFileDimensions(null)
        setSelectedOption(null)
        setFileName(null)
        setResolutionsOptions([])
        setColorOptions([])
        setSelectedColor(null)
        setUploadProgress(0)
        setQty(1)
        setUploading(false)
    }

    function getDisableButton() {
        if (!currentUser) return false
        if (!currentUser.emailVerified) {
            return false
        } else if (uploading) {
            return false
        } else {
            return true
        }
    }

    function getDisabledButtonText() {
        if (!currentUser) return "Debes inicar sesión para agregar al carro"
        if (!currentUser.emailVerified) {
            return "Debes verificar tu correo electronico para agregar al carro"
        } else {
            return null
        }
    }

    function handleAddToCart() {
        if (!uploadedFile) return

        window.fbq('track', 'AddToCart', {
            file_name: fileName,
            value: selectedOption.cost,
            currency: 'ARS'
        });

        setUploading(true)

        const itemId = uuidV4(0)
        const itemFileName = `x - ${fileName} - ${selectedColor.label} - ${selectedOption.label}`
        const uploadTask = storage.ref(`/files/${currentUser.uid}/${itemId}/${itemFileName}.stl`)
            .put(uploadedFile)

        uploadTask.on('state_changed', snapshot => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log("progress: ", progress)
            setUploadProgress(progress)
        }, () => {
            setMessage({
                severity: "error",
                message: "Hubo un problema al subir el archivo"
            })
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then(url => {
                let item = {
                    name: fileName,
                    quantity: qty,
                    downloadUrl: url,
                    selectedOption: selectedOption,
                    selectedColor: selectedColor
                }

                database.carts.doc(currentUser.uid).collection("items").doc(itemId).set(item)
                    .then(() => {
                        setMessage({
                            severity: "success",
                            message: "Agregado al carro!"
                        })
                        handleDelete()
                    })
                    .catch((error) => {
                        setMessage({
                            severity: "error",
                            message: "Hubo un problema al agregar al carro"
                        })
                        uploadTask.snapshot.ref.delete()
                    });
            })
        })
    }

    function getObjectCost(volume) {
        var getObjectOptions = functions.httpsCallable('getOptions')
        getObjectOptions({
            volume: volume
        })
            .then((response) => {
                window.fbq('trackCustom', 'ObjectPriceReturned', {
                    file_name: fileName,
                    value: response.data.resolutionOptions[1].cost,
                    currency: 'ARS'
                });

                analytics.logEvent('ObjectPriceReturned');
                setSelectedColor(response.data.colorOptions[0])
                setColorOptions(response.data.colorOptions)
                setResolutionsOptions(response.data.resolutionOptions)
                setSelectedOption(response.data.resolutionOptions[1])
            })
    }

    return (
        <Container
            maxWidth="lg"
            style={{
                // backgroundImage: `url(${"images/bg.png"})`,
                // backgroundPosition: 'bottom',
                // backgroundRepeat: 'no-repeat',
                // height: '100vh',
                backgroundSize: '100%',
                resizeMode: 'contain',
                marginTop: '20px',
                marginBottom: '50px'
            }}>

            <Snackbar
                open={message}
                onClose={() => { setMessage(null) }}
                autoHideDuration={3000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}>
                <Alert severity={message ? message.severity : "info"} sx={{ width: '100%' }}>
                    {message && message.message}
                </Alert>
            </Snackbar>

            <Grid container spacing={4} direction="row" justifyContent="center" alignItems="center" >
                {/* upload de archivo */}
                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        {/* texto principal */}

                        <Typography variant="body1" color="initial">Formatos admitidos: .STL</Typography>

                        <Paper elevation={4} style={{
                            width: "100%",
                            height: "100%",
                            padding: "2vh",
                            borderRadius: 20,
                            alignContent: "center",
                            justifyContent: "center"
                        }}>
                            <Box style={{
                                padding: "5px",
                                aspectRatio: "1.3",
                                borderRadius: 10,
                                border: '2px',
                                borderColor: '#42364C',
                                borderStyle: 'dashed',
                                backgroundColor: '#ffffff',
                                // color: '#6622EE',
                                outline: 'none',
                                // transition: 'border .24s ease-in-out'
                            }}>

                                {url ?
                                    <StlViewer
                                        url={url ? url : null}
                                        groundColor='rgb(255, 255, 255)'
                                        objectColor='#48484B'
                                        skyboxColor='rgb(255, 255, 255)'
                                        gridLineColor='rgb(0, 0, 0)'
                                        lightColor='rgb(255, 255, 255)'
                                        modelDimension={handleModelDimension}
                                    /> :
                                    <DropZone didUploadFiles={files =>
                                        handleFileUploaded(files[0])
                                    } />
                                }
                            </Box>

                            <Collapse in={fileDimensions != null}>
                                {fileDimensions ?
                                    <Box mt={2}>
                                        <ModelDimensions dimensions={fileDimensions} onDelete={handleDelete} />
                                    </Box>
                                    : null}
                            </Collapse>

                        </Paper>

                    </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box>
                        {selectedOption ?
                            <ConfigurationOptions
                                resolutionOptions={resolutionOptions}
                                selectedOption={selectedOption}
                                onOptionChange={handleSelectedOption}
                                colorOptions={colorOptions}
                                selectedColor={selectedColor}
                                colorChange={handleColorChange}
                                fileName={fileName}
                                fileNameValid={fileName.length != 0}
                                fileNameChange={handleNameChange}
                                addToCart={handleAddToCart}
                                tooltipText={getDisabledButtonText()}
                                enabled={getDisableButton()}
                                quantity={qty}
                                quantityChange={(qty) => { setQty(parseInt(qty)) }}
                                uploading={uploading}
                                uploadProgress={uploadProgress}
                            />

                            : <EmptyState loading={fileName != null && resolutionOptions.length === 0} />}
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}