import React from 'react'
import { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import StraightenIcon from '@mui/icons-material/Straighten';
import Stack from '@mui/material/Stack';
import { IconButton } from '@material-ui/core'
import { Delete } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export default function ModelDimensions(props) {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    function handleDeleteConfirm() {
        setOpen(false)
        props.onDelete()
    }

    function handleClose() {
        setOpen(false)
    }

    return (
        <div>
            <Grid item container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                {/* X,Y,Z */}
                <Grid item xs={7}>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="left"
                        alignItems="left">
                        <StraightenIcon />
                        <Typography variant="body1">X: {props.dimensions.x}</Typography>
                        <Typography variant="body1">Y: {props.dimensions.y}</Typography>
                        <Typography variant="body1">Z: {props.dimensions.z}</Typography>
                    </Stack>
                </Grid>

                {/* Volume */}
                <Grid item xs={4}>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="left"
                        alignItems="left">

                        <ViewInArIcon />
                        <Typography variant="body1">{Math.round(props.dimensions.volume / 1000)}cm³</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={1}>
                    <IconButton size="large" color="primary" aria-label="add" style={{ outline: 'none' }} onClick={handleClickOpen}>
                        <Delete />
                    </IconButton>
                </Grid>
            </Grid>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Eliminar archivo"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Estás seguro que deseas eliminar el archivo cargado?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus>Si</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
