import React from 'react'

import { Link, Stack, Grid, Alert, AlertTitle, Box } from '@mui/material'
import Typography from '@material-ui/core/Typography'
import { functions } from '../../firebase'

export default function OrderItem(props) {
    function OrderStatusDetail(status) {
        switch (status) {
            case "no_payment":
                return {
                    title: "Esperando Pago...",
                    severity: "warning"
                }
            case "payment_approved":
                return {
                    title: "Revisando Archivos",
                    subtitle: "Pago aprobado",
                    severity: "info"
                }
            case "payment_in_process":
                return {
                    title: "Pago pendiente",
                    severity: "warning"
                }
            case "payment_rejected":
                return {
                    title: "Pago rechazado",
                    severity: "error"
                }
            case "refund_in_process":
                return {
                    title: "Devolviendo Dinero",
                    subtitle: "Pendiente...",
                    severity: "warning"
                }
            case "payment_refunded":
                return {
                    title: "Archivos rechazados",
                    subtitle: "Pago devuelto",
                    severity: "warning"
                }
            case "files_approved":
                return {
                    title: "Archivos Aceptados",
                    subtitle: "En cola de impresión",
                    severity: "success"
                }

            case "printed":
                return {
                    title: "Impreso",
                    subtitle: "Envio pendiente",
                    severity: "success"
                }

            case "printing":
                return {
                    title: "Imprimiendo",
                    severity: "info"
                }

            case "shipped":
                return {
                    title: "Enviado",
                    severity: "info"
                }

            case "delivered":
                return {
                    title: "Entregado",
                    severity: "success"
                }
            default:
                return {}
        }
    }

    function OrderStatusAlert() {
        if (!props.order) { return null }

        const orderStatusDetail = OrderStatusDetail(props.order.status)
        if (orderStatusDetail.title && orderStatusDetail.subtitle) {
            return (<Alert severity={orderStatusDetail.severity} variant="filled">
                <AlertTitle>{orderStatusDetail.title}</AlertTitle>
                {orderStatusDetail.subtitle}
            </Alert>)
        } else if (orderStatusDetail.title) {
            return (<Alert severity={orderStatusDetail.severity} variant="filled">
                {orderStatusDetail.title}
            </Alert>)
        } else {
            return null
        }
    }

    return (
        <Box sx={{}}>
            <Stack spacing={2}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowSpacing={2} columnSpacing={0}
                >
                    <Grid item>
                        <Typography variant="h5" color="primary">Orden: {props.order.id}</Typography>
                    </Grid>

                    <Grid item>
                        {OrderStatusAlert()}
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowSpacing={2} columnSpacing={0}
                >

                    <Grid item>
                        <Typography component="div" variant="h5">
                            Total: ${props.order.total}
                        </Typography>
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    )
}
