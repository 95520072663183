import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import './home.css'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Footer from '../../components/footer/footer'
import { Link } from "react-router-dom";
import { Stack } from '@mui/material'

function home() {
    return (
        <Container>
            <Grid container
                // maxWidth="lg"
                // direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ 'background': '#2A2C36' }}
            >

                <Grid item xs={12} sm={6}>
                    <Stack
                        direction="column"
                    >

                        <Typography variant="h6" color="textPrimary">Impresión 3d</Typography>

                        <Typography variant="h2" color="textPrimary" >
                            Potencial 3D <br />
                            a tu alcance
                        </Typography>

                        <Typography variant="h6" color="textPrimary" >
                            Envianos tu proyecto y te asesoramos en <br />
                            todo el proceso de fabricación. <br />
                            Dale vida a tus ideas.
                        </Typography>



                        <Button variant="contained" color="primary" className="btn-1">
                            <Link to="/cotizador">Área de impresión</Link>
                        </Button>

                        <Button variant="outlined" color="primary" className="btn-2">
                            Ver video
                        </Button>

                    </Stack>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <img src="images/preent-home.png" alt="" className="imagen1" />
                </Grid>

            </Grid>

            <Grid container xs={12} sm={12}
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ 'background': '#E5E5E5' }}>


                <Grid item >
                    <Box mt={6}>
                        <Typography variant="h6" color="grey400">Impresión 3d</Typography>
                    </Box>
                </Grid>

                <Grid item>
                    <Box mt={3} mb={6}>
                        <Typography variant="h3" color="initial" >Rápido, simple y fácil</Typography>
                    </Box>

                </Grid>

                <Grid item container xs={12} sm={4}
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                >

                    <Grid item container xs={4}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >

                        <div className="circle mrg2">1</div>
                        <img src="images/upload.png" alt="" className="mrg2" />
                        <Typography variant="h6" color="initial" className="mrg2">subí tu archivo</Typography>

                    </Grid>

                    <Grid item container xs={4}
                        direction="column"
                        alignItems="center"
                        justifyContent="center">

                        <div className="circle mrg2" >2</div>
                        <img src="images/model.png" alt="" className="mrg2" />
                        <Typography variant="h6" color="initial" className="mrg2">lo imprimimos</Typography>

                    </Grid>

                    <Grid item container xs={4}
                        direction="column"
                        alignItems="center"
                        justifyContent="center" spacing={5}>

                        <div className="circle mrg2" >3</div>
                        <img src="images/box.png" alt="" className="mrg2" />
                        <Typography variant="h6" color="initial" className="mrg2">te lo enviamos</Typography>

                    </Grid>
                </Grid>

                <Grid item>
                    <Box mt={8} mb={18}>
                        <Typography variant="h6" color="initial" className="mrg3">
                            Breve explicación.... ¿Qué es Preent? ¿Qué servicios ofrece?
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Footer />


        </Container>
    )
}

export default home
