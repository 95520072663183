import React from 'react'
import { useDropzone } from 'react-dropzone';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Container from '@material-ui/core/Container'
import Stack from '@mui/material/Stack';

const baseStyle = {
  display: "flex",
  justifyContent: 'center', alignItems: 'center',
  width: "100%",
  height: "100%",
  outline: 'none'
};

function DropZone(props) {

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ accept: '.stl', onDropAccepted: files => onDropAccepted(files), multiple: false });

  function onDropAccepted(acceptedFiles) {
    props.didUploadFiles(acceptedFiles)
  }

  const style = React.useMemo(() => ({
    ...baseStyle
  }), [
    isDragActive
  ]);

  return (
    <Container
      style={{
        padding: "0px 0px",
        width: "100%", height: "100%",
      }}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center">
          <CloudUploadIcon fontSize='large' color='primary' />
          <Typography variant="h6" color="secondary">Arrastrá tu archivo acá</Typography>
          <Button variant="contained" color="primary">
            Buscar Archivo
          </Button>
        </Stack>
      </div>
    </Container>
  )
}

export default DropZone
