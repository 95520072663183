import React, { useState } from 'react'
import { Card, Button, Alert } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import CenteredContainer from './CenteredContainer'
import { functions } from '../../firebase'

export default function Profile() {
    const [error, setError] = useState('')
    const { currentUser, logout, convertUserToAdmin } = useAuth()
    const history = useHistory()

    // async function handleLogout() {
    //     setError('')

    //     try {
    //         await logout()
    //         history.push('/login')
    //     } catch {
    //         setError('Error al cerrar la sesión')
    //     }
    // }

    // async function makeAdmin() {
    //     setError('')

    //     try {
    //         functions.httpsCallable('setCustomClaim')()
    //         setError('Done! Now you are admin')
    //     } catch (error) {
    //         setError(error)
    //     }
    // }

    return (
        <CenteredContainer>
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">Perfil</h2>
                    {error && <Alert variant="danger" >{error}</Alert>}
                    <strong>Email:</strong> {currentUser.email}
                    <Link to="/update-profile" className="btn btn-primary w-100 mt-3">
                        Actualizar Perfil
                    </Link>
                </Card.Body>
            </Card>
            {/* <div className="w-100 text-center mt-2">
                <Button variant="link" onClick={handleLogout}>Cerrar Sesión</Button>
            </div> */}
            {/* <div className="w-100 text-center mt-2">
                <Button variant="link" onClick={makeAdmin}>Make myself admin</Button>
            </div> */}
        </CenteredContainer>
    )
}
