import React from 'react'
import { Container, Paper } from '@material-ui/core'

export default function CenteredContainer({ children }) {

    const paperStyle = {
        padding: "4vh",
        width: "100%",
        borderRadius: "15px",
        margin: "20px auto"
    }

    return (
        <Container maxWidth="xs">
            <Paper elevation={10} style={paperStyle}>
                {children}
            </Paper>
        </Container>
    )
}
